import { PaginationButtons } from '../../../../../components/Table';
import GlobalSkeleton from '../../../../../components/Global/GlobalSkeleton';
import { useNavigate } from 'react-router-dom';
import { EmptyWithdrawHistoryGif } from '../../../../../images/wallets';

interface Column {
  label: string;
  key: keyof Row; // Key should be a property of Row
  classNames?: string;
}

interface Row {
  id: string;
  classNames?: string;
  [key: string]: any; // Cell Data
}

interface FooterNavigation {
  text: string;
  url: string;
}
interface Props {
  title?: string;
  columns: Column[];
  rows: any[];
  filters?: any[];
  loading?: boolean;
  footerNavigation?: FooterNavigation;
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (page: number) => void;
  customHeader?: React.ReactElement;
  renderNoData?: React.ReactElement;
}

const CloudKTable = ({
  title,
  columns,
  rows,
  filters,
  loading = false,
  currentPage = 1,
  totalPages,
  onPageChange,
  footerNavigation,
  customHeader,
  renderNoData = <div className="text-center">No Data</div>
}: Props) => {
  const navigate = useNavigate();

  const noData: boolean = !loading && rows.length === 0;

  return (
    <div className="bg-white rounded-xl border border-borderBlue p-6 shadow-cardShadow">
      <div className="flex justify-between mb-6 md:mb-11 flex-wrap gap-4">
        {customHeader ?? <h4 className="text-lg font-medium">{title}</h4>}
        <div className="flex gap-3 flex-wrap">{filters}</div>
      </div>
      { noData ? renderNoData: <div className="overflow-auto">
        <table className="table-auto w-full">
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  className={`text-sm text-start font-normal text-primaryGrey border-b border-chartsCardBorder p-3 whitespace-nowrap ${column.classNames}`}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading
              ? renderLoadingSkeleton()
              : rows.map((row) => (
                    <tr key={row.id}>
                      {columns.map((column) => (
                        <td
                          key={column.key}
                          className="py-5 px-3 text-secondaryBlack whitespace-nowrap border-b border-[#f5f5f5] text-sm"
                        >
                          {row[column.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
          </tbody>
        </table>
      </div>}
      
      {
        !noData && <div
        className={`py-6 flex flex-col md:flex-row w-full gap-2 justify-${footerNavigation ? 'between' : 'center'}`}
      >
        {footerNavigation && (
          <button
            className="text-[#292929] text-sm underline"
            onClick={() => navigate(`${footerNavigation.url}`)}
          >
            {footerNavigation.text}
          </button>
        )}
        <div className={`flex justify-center`}>
          <PaginationButtons
            onChange={onPageChange}
            page={currentPage}
            totalPage={totalPages}
          />
        </div>
      </div>
      }
    </div>
  );


  function renderLoadingSkeleton() {
    return columns.map((_, index: number) => (
      <td className="py-5 px-3 border-b border-[#f5f5f5]" key={index}>
        <GlobalSkeleton animationValue="pulse" height={40} width={100} />
      </td>
    ));
  }
};

export default CloudKTable;
