export const frame = '/img/wallets/frame.jpg';
export const back = '/img/wallets/back.svg';

export const walletsDeposit = '/img/wallets/deposit.svg';
export const walletsWithdraw = '/img/wallets/withdraw.svg';
export const walletsTransfer = '/img/wallets/transfer.svg';
// export const myWallet = '/img/wallets/myWallet.png';

export const walletsDepositDark = '/img/wallets/deposit-dark.svg';
export const walletsWithdrawDark = '/img/wallets/withdraw-dark.svg';
export const walletsTransferDark = '/img/wallets/transfer-dark.svg';

export const mlogo = '/img/wallets/mlogo.svg';

export const LYK_Circle = '/img/wallets/LYK_Circle.svg';
export const M_Circle = '/img/wallets/M_Circle.svg';
export const S_Circle = '/img/wallets/S_Circle.svg';

export const empty = '/img/wallets/empty.svg';
export const walletCardBg = '/img/wallets/myWallet.png';
export const EmptyTransactionHistory =
  '/img/wallets/EmptyTransactionHistory.png';
export const EmptyDepositHistory = '/img/wallets/EmptyDepositHistory.png';
export const EmptyWithdrawHistoryGif = '/img/wallets/EmptyWithdrawHistory.gif';
export const EmptyDepositHistoryGif = '/img/wallets/EmptyDepositHistory.gif';
export const EmptySwapHistoryGif = '/img/wallets/EmptySwapHistory.gif';
export const DefiWalletBannerImg = '/img/wallets/defiWalletBannerImg.png';
export const DepositAndStackIcon = '/img/wallets/depositAndStake.png';
export const DepositAndStackPendingIcon = '/img/wallets/deposit-pending.png';
export const DeffiBannerGif = '/img/wallets/deffiBannerGif.gif';

export const EmptyHardwareList = '/img/cloudk/EmptyHardwareList.png';
export const EmptyTopMachineList = '/img/cloudk/EmptyMachineList.svg';
export const EmptyLicenseList = '/img/cloudk/EmptyLicenseList.png';

export const EmptySwapHistory = '/img/wallets/EmptySwapHistory.png';

export const pieChartBg = '/img/wallets/pie-chart-bg.png';
export const bgOpacityClass = '/img/wallets/bg-opacity-class.png';

export const securityIcon = '/img/wallets/securityIcon.gif';
