import { useSelector } from 'react-redux';
import CardLayout from '../../../../../../components/Cards/CardLayout';
import GaugeChart from '../../../../../../components/Cloud/Test1Chart';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import {
  formatNumberWithCommas,
  toFixedWithoutRoundingOff,
} from '../../../../../../utils/utils';
import { ReactComponent as Spinner } from '../../../../../../SVGIcons/spinner.svg';

const TotalLinked = ({ minter, nftStakePercentage }: any) => {
  const { allHardwareLoading } = useSelector(selectCloudSlice);
  return (
    <CardLayout className="md:col-span-5 lg:col-span-5 col-span-12  p-3 flex flex-col justify-between gap-2 !shadow-boxShadow relative bg-[#FFFFFF]">
      {allHardwareLoading ? (
        <div className="flex justify-center items-center flex-1 w-full">
          <div className="justify-center items-center self-center">
            <div className="flex flex-col gap-5">
              <Spinner className="animate-spin h-6" />
            </div>
          </div>
        </div>
      ) : (  <section className="flex flex-col gap-2 ">
        <span className="text-3xl font-medium">
          {minter?.totalLockNft || 0}{' '}
          <span className="text-sm text-[#616161]">
            out of {minter.minterData.maxNFTCount || 0}
          </span>
        </span>
        <span className="text-sm font-medium text-[#616161]">Linked NFTs</span>
        <div className="w-[168px] flex justify-end absolute h-20 bottom-1 md:bottom-4 right-0">
          <div className="flex items-center gap-2 relative justify-center  ">
            <GaugeChart
              containerWidth={168}
              containerHeight={80}
              strokeWidth={2}
              value={nftStakePercentage}
              limit={100}
              startText=""
              endText={``}
              showStartText={false}
              showEndText={false}
              dotRadius={4}
            />

            <div className="absolute w-full flex flex-col font-bold text-center text-primaryDark bottom-2">
              <div className="flex justify-center text-xs font-semibold truncate text-[#0E173F]">
                {formatNumberWithCommas(
                  Number(
                    toFixedWithoutRoundingOff(
                      Number(nftStakePercentage || 0),
                      2
                    )
                  )
                )}{' '}
                %
              </div>
              <div className=" flex justify-center text-[8px] text-[#616161]">
                Available Capacity
              </div>
            </div>
          </div>
        </div>
      </section>)}
    </CardLayout>
  );
};

export default TotalLinked;
