import { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import CardLayout from '../../../../../components/Cards/CardLayout';
import GlobalModal from '../../../../../components/Global/GlobalModal';
import SimpleSlider from '../../../../../components/Slider/CardSlider';
import useModalHook from '../../../../../hooks/useModalHook';
import { hardwareImg, mobileHardwareImg } from '../../../../../images/cloudk';
import { EmptyHardwareList } from '../../../../../images/wallets';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { ReactComponent as LinkIcon } from '../../../../../SVGIcons/cloud/link.svg';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import ImageWithFallback from '../../Common/ImageWithFallback/ImageWithFallback';
import ConnectNewMinter from './ConnectNewMinter/ConnectNewMinter';
import ConnectThroughQR from './ConnectThroughQR/ConnectThroughQR';
import HardwareDetailModal from './HardwareDetailModal/HardwareDetailModal';

const AllHardwares = () => {
  const sliderRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [minter, setMinter] = useState<any>({});

  const {
    open: openConnectQRModal,
    handleClose: handleCloseConnectQRModal,
    handleOpen: handleOpenConnectQRModal,
  } = useModalHook();

  const {
    open: openConnectModal,
    handleClose: handleCloseConnectModal,
    handleOpen: handleOpenConnectModal,
  } = useModalHook();
  const {
    open: openDetailModal,
    handleClose: handleCloseDetailModal,
    handleOpen: handleOpenDetailModal,
  } = useModalHook();

  const { allHardwareLoading, allHArdwaresData } =
    useSelector(selectCloudSlice);

  const { getAllHardwares } = useCloudkActions();

  const handlePrev = () => {
    if (sliderRef.current && currentIndex > 0) {
      sliderRef.current.slidePrev();
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (sliderRef.current && !isAtEnd) {
      sliderRef.current.slideNext();
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    getAllHardwares();
  }, []);
  useEffect(() => {
    if (minter?._id) {
      // Find the updated minter from the new `allHArdwaresData`
      const updatedMinter = allHArdwaresData?.find(
        (item: any) => item._id === minter._id
      );
      if (updatedMinter) {
        setMinter(updatedMinter); // Update the state with the latest `minter`
      }
    }
  }, [allHArdwaresData, minter?._id]);
  return (
    <CardLayout className=" col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow bg-[#FFFFFF]">
      <section className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <h1 className="text-secondaryBlack font-medium sm:text-lg text-sm">
            Hardwares
          </h1>
          {/* <div
            className=" top-5 justify-end right-5 bottom-[-6px] md:bottom-5 md:right-[10px] gap-1 flex cursor-pointer"
            onClick={() => {
              //   navigate('/wallet/history');
            }}
          >
            <span className="text-sm font-normal">View All</span>
            <div className="h-5 w-5">
              <ArrowRight />
            </div>
          </div> */}
        </div>
      </section>
      <div className="relative">
        <div className="flex gap-2 overflow-x-hidden ">
          {allHardwareLoading ? (
            <div className="flex justify-center items-center flex-1  c w-full">
              <div className="justify-center items-center self-center">
                <div className="flex flex-col gap-5">
                  <Spinner className="animate-spin h-6" />
                </div>
              </div>
            </div>
          ) : allHArdwaresData?.length > 0 ? (
            <>
              <SimpleSlider
                onSwiper={(swiper) => {
                  sliderRef.current = swiper;
                }}
                onSlideChange={(swiper) => {
                  setCurrentIndex(swiper.activeIndex);
                  setIsAtEnd(swiper.isEnd); // Update end status here
                }}
                className="w-full cursor-grab"
                spaceBetween={8}
                slidesPerView={'auto'}
                modules={[Navigation]}
                navigation={false} // Disable default Swiper navigation
                breakpoints={{
                  768: {
                    slidesPerView: 'auto',
                  },
                }}
              >
                  {allHArdwaresData?.map((item: any, index: number) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className="!w-[220px] h-full pt-8 bg-[#FFFFFF]"
                    >
                      <div
                        style={{
                          background: item.upgradeAble
                            ? 'linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)'
                            : '',
                          border: !item.upgradeAble ? '1px solid #D9E4F1' : '',
                        }}
                        className={` p-[2px] relative rounded-lg h-64`}
                      >
                        {item.upgradeAble && (
                          <div
                            style={{
                              background:
                                'linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)',
                            }}
                            className="cursor-pointer shadow-lg w-32 p-2 absolute  h-6 -top-3 left-1/2 -translate-x-1/2 z-20 rounded-xl flex justify-center items-center "
                          >
                            {' '}
                            <span className="font-medium text-white  text-xs">
                              Upgrade Software
                            </span>
                          </div>
                        )}
                        <div
                          key={index}
                          className={`bg-white p-3 w-[216px] h-full rounded-lg relative flex flex-col justify-between gap-3`}
                        >
                          <div className="w-full flex justify-center mt-2">
                            <ImageWithFallback
                              src={item.minterData?.documents[0]?.link}
                              alt="machine-image"
                              classNames="h-[103px] w-[176px]"
                              fallbackSrc={''}
                            />
                          </div>

                          <div className="flex flex-col gap-2 w-full items-center">
                            <div className="flex flex-col gap-2 justify-center items-center">
                              <div className="flex gap-1 items-center">
                                <span className="font-medium text-[#121212]">
                                  {item?.minterData?.name}
                                </span>
                                <div className="relative w-2 h-2 ml-1">
                                  <span className="flex gap-2 h-2 w-2">
                                    <span
                                      className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${
                                        item?.minterData?.status === 'active'
                                          ? 'bg-primaryGreen'
                                          : 'bg-primaryRed'
                                      }`}
                                    ></span>
                                    <span
                                      className={`relative inline-flex rounded-full h-2 w-2 ${
                                        item?.minterData?.status === 'active'
                                          ? 'bg-primaryGreen'
                                          : 'bg-primaryRed'
                                      }`}
                                    ></span>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="flex gap-1 justify-center items-center my-1">
                              <div className="">
                                <span className=" text-sm font-medium text-[#939393]">
                                  Linked NFTS:
                                </span>
                              </div>

                              <div className="">
                                <span className="font-medium text-sm text-[#121212]">
                                  {`${item?.totalLockNft ?? 0}/${item?.minterData?.maxNFTCount ?? 0}`}
                                </span>
                              </div>
                            </div>

                            {
                              // false
                              item?.status === 'connected' ? (
                                <span
                                  className="text-xs text-[#484848] underline cursor-pointer"
                                  onClick={() => {
                                    setMinter(item);
                                    handleOpenDetailModal();
                                  }}
                                >
                                  View Minter Details
                                </span>
                              ) : (
                                <button
                                  // disabled={item.disable}
                                  type="button"
                                  // className={`w-44 h-6 rounded-[16px] flex justify-center items-center text-xs gap-1 text-white ${!item.lock && item.disable ? 'bg-[#C0D5DF] cursor-not-allowed' : 'hover:bg-primary'}  bg-[#00A5EF] `}
                                  className={`w-44 h-6 rounded-[16px] flex justify-center items-center text-xs gap-1 text-white ${'hover:bg-primary'}  bg-[#00A5EF] `}
                                  onClick={() => {
                                    if (item.type === 'phone') {
                                      handleOpenConnectQRModal();
                                    } else {
                                      setMinter(item);
                                      handleOpenConnectModal();
                                    }
                                  }}
                                >
                                  <div className="h-3 w-3">
                                    <LinkIcon />
                                  </div>{' '}
                                  Connect
                                </button>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </SimpleSlider>
            </>
          ) : (
            <div className="flex-1 flex justify-center  items-center w-full xxs:px-2 sm:px-0  py-3">
              <div className="flex flex-col gap-2 items-center ">
                <div className=" w-44 lg:w-64 h-auto">
                  <img src={EmptyHardwareList} alt="empty transactions" />
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium">
                    You don’t have Hardwares
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        {allHArdwaresData?.length > 0 && (
          <>
            <div className="absolute top-0 right-0 h-full w-[10%] pointer-events-none bg-gradient-to-l from-white md:block hidden"></div>
            <div className="md:hidden flex gap-4 mt-2">
              <button
                onClick={handlePrev}
                disabled={currentIndex === 0}
                className={` rounded-full ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
              >
                <IoIosArrowBack size={20} />
              </button>
              <button
                onClick={handleNext}
                disabled={isAtEnd}
                className={` rounded-full ${isAtEnd ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
              >
                <IoIosArrowForward size={20} />
              </button>
            </div>
          </>
        )}
      </div>

      <GlobalModal
        isOpen={openConnectModal}
        onClose={handleCloseConnectModal}
        size="3xl"
        childrenClass="!px-0 !py-0 small-scroll"
        title={'Connect New Minter'}
        noHeader={false}
        headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
      >
        <ConnectNewMinter
          minter={minter}
          handleCloseConnectModal={handleCloseConnectModal}
        />
      </GlobalModal>

      <GlobalModal
        isOpen={openDetailModal}
        onClose={handleCloseDetailModal}
        size="3xl"
        childrenClass="!px-0 !py-0 small-scroll"
        // title={'Connect New Minter'}
        noHeader={true}
        headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
      >
        <HardwareDetailModal minter={minter} />
      </GlobalModal>

      <GlobalModal
        isOpen={openConnectQRModal}
        onClose={handleCloseConnectQRModal}
        size="xl"
        childrenClass="!px-3 !py-0 !pt-6 small-scroll"
        noHeader={false}
        headerClassName="!p-0  font-medium text-lg text-[#0E173F]"
      >
        <ConnectThroughQR />
      </GlobalModal>
    </CardLayout>
    // )
  );
};

export default AllHardwares;
