import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setCloudkSlice } from './cloudkSlice';
import ApiRequestCloudk from '../../utils/network/ApiRequestCloudk';
import {
  ADD_MINTING_TOKEN,
  ALL_DETAILS,
  ALL_HARDWARES,
  ALL_USER_LICENSES,
  ALL_USER_LINK_LICENSES,
  ALL_USER_UNLOCK_LICENSES,
  CLAIM_CLOUDK_REWARD,
  CLAIM_HARDWARE_GENERATED_REWARD,
  CLAIMABLE_REWARD_CLOUDK,
  CLAIMED_REWARD_AUTO_LINK,
  CLOUDK_TRANSACTION_HISTORY,
  CONNECT_NEW_MINTER,
  DE_LINK_HARDWARE,
  LINK_LICENSE_WITH_MINTER,
  LINKED_MYLY,
  MACHINE_REWARD_HISTORY,
  MINTER_REWARD_HISTORY_LICENSES_DROPDOWN,
  MINTER_REWARDS,
  REWARD_ANALYTICS_GRAPH_API,
  SPECIFIC_CLAIM_CLOUDK_REWARD,
  TRANSACTION_HISTORY_LICENSE_FILTER,
  UNLOCK_LICENSE,
  UNLOCKED_LICENSE_CLAIM,
  UNLOCKED_LICENSE_DETAILS,
  UNLOCKED_LICENSE_TRANSACTIONS,
  UNLOCKED_LICENSE_VENTING,
  USER_LICENSE_ACTIVE_COUNT,
  USER_MINTER_DETAIL,
  USER_MINTER_LINKED_LICENSE,
} from '../../utils/network/ApiEndpoints';
import { AxiosResponse } from 'axios';
import { licenseUnlockActions } from '../Cloudk2/cloudk2LicenseUnlockSlice';

export const useCloudkActions = () => {
  const dispatch = useDispatch();
  const cloudKClaimableReward = async () => {
    dispatch(
      setCloudkSlice({
        claimableRewardLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: CLAIMABLE_REWARD_CLOUDK,
      })
      .then((response) => {
        dispatch(
          setCloudkSlice({
            claimableRewardData: response.data.data.tokens,
            isGenerationSwitch: response.data.data.isAutoCompoundOn,
            claimableRewardLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            claimableRewardLoading: false,
          })
        );

        if (error.data) {
          // toast.error(error.data.message);
        }

        return error;
      });
  };

  const rewardAnalyticsGraphDataFunc = async (
    type: string,
    timePeriod: string
  ) => {
    dispatch(
      setCloudkSlice({
        rewardAnalyticsGraphLoading: true,
      })
    );

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${REWARD_ANALYTICS_GRAPH_API}`,
        params: {
          type: type,
          timePeriod: timePeriod,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;

        dispatch(
          setCloudkSlice({
            rewardAnalyticsGraphData: data,
            rewardAnalyticsGraphLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            rewardAnalyticsGraphLoading: false,
          })
        );
        if (error.data) {
          // toast.error(error.data.message);
        }
        return error;
      });
  };

  const getAllUserLinkLicenses = async ({
    page,
    limit,
    query,
    toDate,
    fromDate,
    status,
  }: any) => {
    dispatch(
      setCloudkSlice({
        allUserLinkLicienceLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_USER_LINK_LICENSES,
        params: {
          page,
          limit,
          query,
          toDate,
          fromDate,
          status,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allUserLinkLicencesData: response.data.data,
            allUserLinkLicienceLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allUserLinkLicienceLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getUserUnLockAllLicenses = async ({
    page,
    limit,
    query,
    toDate,
    fromDate,
    status,
  }: any) => {
    dispatch(
      setCloudkSlice({
        allUserUnLockLicienceLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_USER_UNLOCK_LICENSES,
        params: {
          page,
          limit,
          query,
          toDate,
          fromDate,
          status,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allUserUnLockLicencesData: response.data.data,
            allUserUnLockLicienceLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allUserUnLockLicienceLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getAllLicenses = async () => {
    dispatch(
      setCloudkSlice({
        allLicienceLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_USER_LICENSES,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allLicencesData: response.data.data,
            allLicienceLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allLicienceLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const unlockLicense = async (licenseId: string) => {
    return await ApiRequestCloudk()
      .request({
        method: 'PUT',
        url: `${UNLOCK_LICENSE}/${licenseId}`,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const fetchUnlockedLicenseDetails = async (licenseId: string) => {
    dispatch(licenseUnlockActions.setSummaryLoading(true));
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${UNLOCKED_LICENSE_DETAILS}/${licenseId}`,
      })
      .then((response: AxiosResponse) => {
        if (response.data?.data) {
          dispatch(licenseUnlockActions.setSummaryData(response.data?.data));
        }
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        throw error;
      }).finally(() => {
        dispatch(licenseUnlockActions.setSummaryLoading(false));
      });
  };
  const claimUnlockedLicenseDailyRewards = async (licenseId: string) => {
    return await ApiRequestCloudk()
      .request({
        method: 'PUT',
        url: `${UNLOCKED_LICENSE_CLAIM}/${licenseId}`,
      })
      .then((response: AxiosResponse) => {
        if (response.data.status) {
          toast.success('Daily rewards claimed successfully!');
          fetchUnlockedLicenseDetails(licenseId);
          fetchUnlockedLicenseVenting(licenseId);
          fetchUnlockedLicenseTransactions(licenseId, { page: 1, limit: 5 });
        }
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      }).finally(() => {
        dispatch(licenseUnlockActions.setSummaryLoading(false));
      });
  };
  const fetchUnlockedLicenseVenting = async (licenseId: string) => {
    dispatch(licenseUnlockActions.setVestingTimelineLoading(true));

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${UNLOCKED_LICENSE_VENTING}/${licenseId}`,
      })
      .then((response: AxiosResponse) => {
        if (response.data?.data) {
          dispatch(
            licenseUnlockActions.setVestingTimelineData(response.data?.data)
          );
        }
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      })
      .finally(() => {
        dispatch(licenseUnlockActions.setVestingTimelineLoading(false));
      });
  };
  const fetchUnlockedLicenseTransactions = async (
    licenseId: string,
    filters: object = {}
  ) => {
    dispatch(licenseUnlockActions.setTransactionsLoading(true));

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${UNLOCKED_LICENSE_TRANSACTIONS}/${licenseId}`,
        params: {
          ...filters,
        },
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch(
            licenseUnlockActions.setTransactionsData({
              totalPages: response.data?.data.totalPages,
              list: response.data?.data?.list,
            })
          );
        }
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      })
      .finally(() =>
        dispatch(licenseUnlockActions.setTransactionsLoading(false))
      );
  };
  const getAllDetails = async () => {
    dispatch(
      setCloudkSlice({
        upgradeAbleMachinesLoading: true,
        detailDataLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_DETAILS,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            upgradeAblesMachinesData: response.data.data,
            upgradeAbleMachinesLoading: false,
            detailDataLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            upgradeAbleMachinesLoading: false,
            detailDataLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getAllHardwares = async () => {
    dispatch(
      setCloudkSlice({
        allHardwareLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_HARDWARES,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allHArdwaresData: response.data.data.list,
            allHardwareLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allHardwareLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getCloudKTransactionHistory = async ({
    page = 1,
    limit = 10,
    id,
    type,
    fromDate,
    toDate,
  }: any) => {
    dispatch(
      setCloudkSlice({
        transactionHistoryLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: CLOUDK_TRANSACTION_HISTORY,
        params: {
          page,
          limit,
          userLicenseId: id,
          type,
          fromDate,
          toDate,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            transactionHistoryData: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            transactionHistoryLoading: false,
          })
        );
      });
  };

  const getMachineRewardHistory = async ({
    page = 1,
    limit = 10,
    fromDate,
    toDate,
    licenseId,
    minterId,
  }: any) => {
    dispatch(
      setCloudkSlice({
        machineRewardHistoryLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: MACHINE_REWARD_HISTORY,
        params: {
          page,
          limit,
          fromDate,
          toDate,
          licenseId,
          minterId,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            machineRewardHistoryData: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            machineRewardHistoryLoading: false,
          })
        );
      });
  };

  const getAllMachinesRewardHistory = async ({
    page = 1,
    limit = 10,
    fromDate,
    toDate,
    licenseId,
  }: any) => {
    dispatch(
      setCloudkSlice({
        allMachinesRewardHistoryLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${MACHINE_REWARD_HISTORY}/all`,
        params: {
          page,
          limit,
          fromDate,
          toDate,
          licenseId,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            allMachinesRewardHistoryData: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            allMachinesRewardHistoryLoading: false,
          })
        );
      });
  };

  const getMinterLicenses = async ({ minterId }: { minterId?: string }) => {
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: MINTER_REWARD_HISTORY_LICENSES_DROPDOWN,
        params: {
          minterId,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            machineRewardsHistoryLicensesDropdown: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };


  const addMintingTokenFunc = async (data: any) => {
    dispatch(setCloudkSlice({ createMintingTokenLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: ADD_MINTING_TOKEN,
        data,
      })
      .then((response: any) => {
        dispatch(setCloudkSlice({ createMintingTokenLoading: false }));
        getAllLicenses();
        getAllUserLinkLicenses({
          limit: 10,
          page: 1,
          status: '',
          date: '',
          query: '',
          fromDate: null,
          toDate: null,
        })
        if (response?.data.status) {
          toast.success(response?.data?.data?.meta?.message);
          getTotalLinkedFunc()
          getCloudKTransactionHistory({
            page: 1,
            limit: 10,

          });
        }
        return response;
      })
      .catch((error: any) => {
        toast.error(error.data.message || 'Staking error');
        dispatch(setCloudkSlice({ createMintingTokenLoading: false }));
        return error;
      });
  };

  const connectNewMinterFunc = async (data: any) => {
    dispatch(setCloudkSlice({ connectNewMinterLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: CONNECT_NEW_MINTER,
        data,
      })
      .then((response: any) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ connectNewMinterLoading: false }));
          toast.success(response.data.data);
          getAllHardwares();
          return response;
        }
      })
      .catch((error: any) => {

        dispatch(setCloudkSlice({ connectNewMinterLoading: false }));
        toast.error(error.data.message);
        return error;
      });
  };

  const getDailyMinterActivityFunc = async ({
    month = 1,
    year = 2024,
  }: any) => {
    dispatch(
      setCloudkSlice({
        dailyActivityLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: MACHINE_REWARD_HISTORY,
        params: {
          month,
          year,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            dailyActivityData: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            dailyActivityLoading: false,
          })
        );
      });
  };

  const deLinkLicenseFunc = async (data: any) => {
    dispatch(setCloudkSlice({ deLinkLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: DE_LINK_HARDWARE,
        data,
      })
      .then((response: any) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ deLinkLoading: false }));
          toast.success(response.data.data);
          getAllHardwares()
          getUserMinterLinkedLicenses({
            page: 1,
            limit: 10,
            id: data?.minterId,
          });
          return response;
        }

      })
      .catch((error: any) => {
        dispatch(setCloudkSlice({ deLinkLoading: false }));
        toast.error(error.data.message);
        return error;
      });
  };

  const getTotalLinkedFunc = async () => {
    dispatch(
      setCloudkSlice({
        upgradeAbleMachinesLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: LINKED_MYLY,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            totalStacked: response.data.data,
            upgradeAbleMachinesLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            upgradeAbleMachinesLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  // const getAllLicensesStatusFunc = async () => {
  //   // dispatch(
  //   //   setCloudkSlice({
  //   //     allLicienceLoading: true,
  //   //   })
  //   // );
  //   return await ApiRequestCloudk()
  //     .request({
  //       method: 'GET',
  //       url: TRANSACTION_HISTORY_LICENSE_STATUS_FILTER,
  //     })
  //     .then((response: AxiosResponse) => {
  //       dispatch(
  //         setCloudkSlice({
  //           allLiciencesStatus: response.data.data,
  //           // allLicienceLoading: false,
  //         })
  //       );
  //       return response;
  //     })
  //     .catch((error: any) => {
  //       // dispatch(
  //       //   setCloudkSlice({
  //       //     allLicienceLoading: false,
  //       //   })
  //       // );
  //       toast.error(error?.data?.message || 'Something went wrong');
  //       return error;
  //     });
  // };
  const getUserMinterDetailFunc = async ({ id }: any) => {
    //  dispatch(
    //    setCloudkSlice({
    //      allLicienceLoading: true,
    //    })
    //  );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: USER_MINTER_DETAIL,
        params: {
          id,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            minterRewardGeneratedData: response.data.data[0],
            // activeLicenses: response.data.data[0].Activecount,
            // inActiveLicenses: response.data.data[0].InactiveCount,
            // totalLicenses:
            //   response.data.data[0].Activecount +
            //   response.data.data[0].InactiveCount,
          })
        );
        return response;
      })
      .catch((error: any) => {
        //  dispatch(
        //    setCloudkSlice({
        //      allLicienceLoading: false,
        //    })
        //  );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getUserMinterLinkedLicenses = async ({
    id,
    page = 1,
    limit = 10,
  }: any) => {
    dispatch(
      setCloudkSlice({
        minterLinkedLicensesLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: USER_MINTER_LINKED_LICENSE,
        params: {
          page: page,
          limit: limit,
          id,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            minterLinkedLicenses: response.data.data,
            minterLinkedLicensesLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            minterLinkedLicensesLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getActiveLicensesCountFunc = async () => {
    dispatch(
      setCloudkSlice({
        totalCountLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: USER_LICENSE_ACTIVE_COUNT,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            // allLiciencesIds: response.data.data.list,
            // allLiciencesStatus: response.data.data.status,
            totalCountLoading: false,
            activeLicenses: response.data.data.Activecount,
            inActiveLicenses: response.data.data.InactiveCount,
            totalLicenses: response.data.data.TotalCount,
            rewardGeneratedTokens: response.data.data.RewardGenerated,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            totalCountLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getAllLicienceIDsFunc = async () => {
    //  dispatch(
    //    setCloudkSlice({
    //      allLicienceLoading: true,
    //    })
    //  );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: TRANSACTION_HISTORY_LICENSE_FILTER,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allLiciencesIds: response.data.data.licensesList,
            allLiciencesStatus: response.data.data.transactionType,
            //  allLicienceLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        //  dispatch(
        //    setCloudkSlice({
        //      allLicienceLoading: false,
        //    })
        //  );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const LinkLicenseWithMinterFunc = async (data: any) => {
    dispatch(setCloudkSlice({ linkLicenseWithMinterLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: LINK_LICENSE_WITH_MINTER,
        data,
      })
      .then((response: any) => {
        dispatch(setCloudkSlice({ linkLicenseWithMinterLoading: false }));
        toast.success('license connect successfully!');
        getAllHardwares()
        // getAllLicenses();
        return response;
      })
      .catch((error: any) => {
        dispatch(setCloudkSlice({ linkLicenseWithMinterLoading: false }));
        toast.error(error.data.message);
        return error;
      });
  };
  const cloudKClaimableRewardAutoLinkFunc = async (data: any) => {
    dispatch(
      setCloudkSlice({
        claimableRewardAutoLinkLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'PUT',
        url: CLAIMED_REWARD_AUTO_LINK,
        data,
      })
      .then((response) => {

        if (response.status === 200) {
          dispatch(
            setCloudkSlice({
              isGenerationSwitch: data.isAutoLink,
              claimableRewardAutoLinkLoading: false,
            })
          );
          toast.success(`Auto link ${data.isAutoLink ? 'activated' : 'deactivated'}`);
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            claimableRewardAutoLinkLoading: false,
          })
        );
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };
  const hardwareGeneratedRewardClaimfunc = async (data: any) => {
    dispatch(
      setCloudkSlice({
        hardwareClaimLoading: true,
      })
    );
    // toast.success('Reward claimed successfully!');

    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: CLAIM_HARDWARE_GENERATED_REWARD,
        data,
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ hardwareClaimLoading: false }));

          if (response.data.status) {
            toast.success('Reward claimed successfully.');
            getMinterRewardFunc(data?.userMinter);
            cloudKClaimableReward()
             getCloudKTransactionHistory({
               page: 1,
               limit: 10,
             });
          }
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            hardwareClaimLoading: false,
          })
        );

        if (error.data) {
          // toast.error(error.data.message);
        }

        return error;
      });
  };


  const getMinterRewardFunc = async (id: any) => {
    dispatch(
      setCloudkSlice({
        MinterRewardLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${MINTER_REWARDS}/${id}`,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            MinterRewards: response.data.data,
            MinterRewardLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            MinterRewardLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };


  const claimCloudkRewardFunc = async () => {
    dispatch(setCloudkSlice({ claimRewardLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: CLAIM_CLOUDK_REWARD,
        // data,
      })
      .then((response: any) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ claimRewardLoading: false }));
          cloudKClaimableReward();
          if (response.data.status) {
            toast.success('Reward claimed successfully.');
            getCloudKTransactionHistory({
              page: 1,
              limit: 10
            })
          }
          return response;
        }
      })
      .catch((error: any) => {
        toast.error(error.data.message);
        dispatch(setCloudkSlice({ claimRewardLoading: false }));
        return error;
      });
  };

  const cloudkSpecificLicenseReward = async (data: any) => {
    dispatch(setCloudkSlice({ specificLicenseLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: SPECIFIC_CLAIM_CLOUDK_REWARD,
        data,
      })
      .then((response: any) => {
        if (response.status === 201) {
          dispatch(setCloudkSlice({ specificLicenseLoading: false }));
          cloudKClaimableReward();
          if (response.data.status) {
            toast.success('Reward claimed successfully.');
            getAllUserLinkLicenses({
              limit: 10,
              page: 1,
              status: '',
              date: '',
              query: '',
              fromDate: null,
              toDate: null,
            });
          }
          return response;
        }
      })
      .catch((error: any) => {
        toast.error(error.data.message);
        dispatch(setCloudkSlice({ specificLicenseLoading: false }));
        return error;
      });
  };
  return {
    cloudkSpecificLicenseReward,
    claimCloudkRewardFunc,
    cloudKClaimableReward,
    getUserUnLockAllLicenses,
    getAllUserLinkLicenses,
    rewardAnalyticsGraphDataFunc,
    getAllLicenses,
    getAllHardwares,
    getCloudKTransactionHistory,
    getMachineRewardHistory,
    getAllDetails,
    addMintingTokenFunc,
    connectNewMinterFunc,
    getDailyMinterActivityFunc,
    deLinkLicenseFunc,
    hardwareGeneratedRewardClaimfunc,
    cloudKClaimableRewardAutoLinkFunc,
    getAllLicienceIDsFunc,
    // getAllLicensesStatusFunc,
    unlockLicense,
    fetchUnlockedLicenseDetails,
    fetchUnlockedLicenseVenting,
    fetchUnlockedLicenseTransactions,
    claimUnlockedLicenseDailyRewards,
    getActiveLicensesCountFunc,
    getUserMinterDetailFunc,
    getUserMinterLinkedLicenses,
    getTotalLinkedFunc,
    LinkLicenseWithMinterFunc,
    getMinterRewardFunc,
    getMinterLicenses,
    getAllMachinesRewardHistory,
  };
};
