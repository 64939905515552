import { useEffect, useState } from 'react';
import { licenseImg } from '../../../../../../images/cloudk';
import { ReactComponent as LinkIcon } from '../../../../../../SVGIcons/cloud/link.svg';
import CloudKSelect from '../../../Common/CloudKSelect/CloudKSelect';
import CloudKTable from '../../../Common/CloudKTable/CloudKTable';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import GlobalModal from '../../../../../../components/Global/GlobalModal';
import SimpleSlider from '../../../../../../components/Slider/CardSlider';
import useModalHook from '../../../../../../hooks/useModalHook';
import {
  EmptyLicenseList,
} from '../../../../../../images/wallets';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import { ReactComponent as Spinner } from '../../../../../../SVGIcons/spinner.svg';
import {
  formatNumberWithCommas,
  toFixedFormat,
  toFixedWithoutRoundingOff,
} from '../../../../../../utils/utils';
import LinkedLicense from '../../../LinkedLicense/LinkedLicense';
import TotalLinked from './TotalLinked';
import RangeDatePicker from '../../../../../Wallet/RangeDatePicker';
import ImageWithFallback from '../../../Common/ImageWithFallback/ImageWithFallback';
import moment from 'moment';
import Tooltips from '../../../../../../components/Tooltips';


const monthsData = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const yearsData = Array.from(
  { length: 11 },
  (_, i) => new Date().getFullYear() - i
);

const HardwareDetailModal = ({ minter }: any) => {
  const {
    MinterRewards,
    MinterRewardLoading,
    minterLinkedLicensesLoading,
    linkLicenseWithMinterLoading,
    minterLinkedLicenses,
    hardwareClaimLoading,
    machineRewardHistoryData,
    machineRewardHistoryLoading,
    dailyActivityLoading,
    deLinkLoading,
    machineRewardsHistoryLicensesDropdown,
  } = useSelector(selectCloudSlice);
  const {
    getMinterRewardFunc,
    hardwareGeneratedRewardClaimfunc,
    getMachineRewardHistory,
    deLinkLicenseFunc,
    getUserMinterLinkedLicenses,
    getMinterLicenses,
  } = useCloudkActions();
  const currentMonth = new Date().getMonth(); // 0 = January
  const currentYear = new Date().getFullYear();
  // State for selected month, year, and days in the month
  const [selectedMonth, setSelectedMonth] = useState<any>(currentMonth);
  const [selectedYear, setSelectedYear] = useState<any>(currentYear);
  const [daysInMonth, setDaysInMonth] = useState<any>(0);
  const [claimIndex, setClaimIndex] = useState<any>(0);
  const [deLinkIndex, setDeLinkIndex] = useState<any>(0);
  const { open, handleClose, handleOpen } = useModalHook();

  const navigate = useNavigate();
  useEffect(() => {
    const days = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    setDaysInMonth(days);
  }, [selectedMonth, selectedYear]);

  const columns = [
    {
      label: 'Date',
      key: 'date',
      classNames: '!text-center text-[#939393] font-medium',
    },
    {
      label: 'Linked License',
      key: 'licenseId',
      classNames: ' !text-center text-[#939393] font-medium',
    },
    {
      label: 'Hardware Benefit',
      key: 'hardwareBenifit',
      classNames: '!text-center text-[#939393] font-medium',
    },
    {
      label: 'License Rewards',
      key: 'licenseReward',
      classNames: '!text-center text-[#939393] font-medium',
    },
  ];

  // @ts-ignore
  const rows = machineRewardHistoryData?.list?.map((row) => ({
    date: (
      <p className="text-[#121212] text-xs text-center">
        {moment(row.createdAt).format('lll')}
      </p>
    ),
    licenseId: (
      <p className="text-[#121212] text-xs text-center ">{row.identifier}</p>
    ),
    hardwareBenifit: (
      <div className="flex flex-col gap-2 items-center w-full">
        <div className="flex gap-3">
          <span className="text-xs text-[#939393]">LYK-R</span>
          <span className="text-xs font-medium text-[#7FC9FF]">
            {toFixedFormat(row.hardWare.lyk_r,4)}
          </span>
        </div>
        <div className="flex gap-3">
          <span className="text-xs text-[#939393]">LYK-W</span>
          <span className="text-xs font-medium text-[#7583FF]">
            {toFixedFormat(row.hardWare.lyk_w,4)}
          </span>
        </div>
      </div>
    ),
    licenseReward: (
      <div className="flex flex-col gap-2  items-center w-full">
        <div className="flex gap-3">
          <span className="text-xs text-[#939393]">LYK-R</span>
          <span className="text-xs font-medium text-[#7FC9FF]">
            {toFixedFormat(row.license.lyk_r)}
          </span>
        </div>
        <div className="flex gap-3">
          <span className="text-xs text-[#939393]">LYK-W</span>
          <span className="text-xs font-medium text-[#7583FF]">
            {toFixedFormat(row.license.lyk_w)}
          </span>
        </div>
      </div>
    ),
  }));

  const [resetFilter, setResetFilter] = useState(false);
  const [filters, setFilters] = useState<{
    limit: number;
    page: number;
    selectedLicense: { label: string; value: string } | null;
    fromDate: string | null;
    toDate: string | null;
    minterId: string | null;
  }>({
    limit: 10,
    page: 1,
    selectedLicense: null,
    fromDate: null,
    toDate: null,
    minterId: minter?._id,
  });
  const [resetDisable, setResetDisable] = useState(true);

  useEffect(() => {
    const noData = !rows?.length;
    const hasFilters =
      filters.selectedLicense || filters.fromDate || filters.toDate;

    // If there's no data and no filters, keep it disabled
    // If there's data but no filters, disable it
    // If there's data or filters are applied, enable it
    setResetDisable(noData || (!noData && !hasFilters));
  }, [rows, filters]);

  useEffect(() => {
    setResetFilter(false);
    getMachineRewardHistory({
      ...filters,
      licenseId: filters.selectedLicense?.value,
    });
    // getCloudKTransactionHistory({
    //     page: filters.page,
    //     limit: filters.limit,
    //     licienceId: filters?.licenseId?.value,
    //     transactionType: filters?.licensesStatus?.value
    // });
  }, [filters]);

  const handleLicenseChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilters((prev: any) => ({
      ...prev,
      selectedLicense: selectedOption,
    }));
  };

  const handleDateChange = (fromDate: string, toDate: string) => {
    const from = moment(fromDate).format('YYYY-MM-DD');
    const to = moment(toDate).format('YYYY-MM-DD');

    setFilters((prev: any) => ({
      ...prev,
      fromDate: from,
      toDate: to,
    }));
  };

  useEffect(() => {
    getMinterLicenses({ minterId: minter?._id });
    getMachineRewardHistory({
      ...filters,
      licenseId: filters.selectedLicense?.value,
    });
    // getUserMinterDetailFunc({ id: minter._id })
    getUserMinterLinkedLicenses({
      page: 1,
      limit: 10,
      id: minter?._id,
    });
    getMinterRewardFunc(minter._id);
  }, []);

  useEffect(() => {
    // getDailyMinterActivityFunc();
  }, [selectedMonth, selectedYear]);
  // const handleMonthChange = (selectedOption: { label: string; value: string }) => {
  //   setSelectedMonth(selectedOption)
  // };

  // const handleYearChange = (selectedOption: { label: string; value: string }) => {
  //   setSelectedYear(selectedOption)
  // };
  const nftStakePercentage = 100 - ((minter?.totalLockNft / minter?.minterData.maxNFTCount) * 100);
  return (
    <div className="flex flex-col gap-2 p-6">
      <div className="flex-col md:flex-row  flex gap-5 items-start md:items-center">
        <span className="font-medium text-4xl text-[#0E173F]">
          {minter?.minterData?.name}
        </span>
        <div className="flex gap-2">
          <button
            //   onClick={() => {
            //       !item.lock && handleOpen()
            //   }}
            //   disabled={item.disable}
            type="button"
            className={`px-4 h-8 rounded-[16px] flex justify-center items-center text-sm gap-1 border border-[#00A5EF] text-[#00A5EF] bg-white`}
          >
            Reboot
          </button>
          {linkLicenseWithMinterLoading ? (
            <div className="flex justify-center items-center flex-1 w-full">
              <div className="justify-center items-center self-center">
                <div className="flex flex-col gap-5">
                  <Spinner className="animate-spin h-6" />
                </div>
              </div>
            </div>
          ) : (
              <Tooltips
                placement="top"
                content={
                  nftStakePercentage <= 0 ? <div className="p-3 text-xs font-normal bg-white rounded-lg shadow-md w-52">
                     No available NFT capacity
                  </div>
                  :<></>
                }
              >
            <button
              onClick={() => {
                handleOpen();
                //   !item.lock && handleOpen()
                // const data ={
                //     minterId: minter?.minterData?._id,
                //     licenseId: '12344'
                // }
                //     LinkLicenseWithMinterFunc(data)
              }}
                disabled={nftStakePercentage <= 0}
              type="button"
                  className={`p-4 h-8 rounded-[16px] flex justify-center items-center text-xs gap-1 text-white ${nftStakePercentage <= 0 ?   'bg-[#C0D5DF] cursor-not-allowed' : 'bg-[#00A5EF]' }`}
            >
              <>
                <div className="h-3 w-3">
                  <LinkIcon />
                </div>{' '}
                Link
              </>
            </button>
              </Tooltips>
          )}
        </div>
      </div>
      {minter.serialNumber ? (
        <span className="text-[#939393] font-medium">
          Serial Number : {minter.serialNumber}
        </span>
      ) : (
        <></>
      )}

      <div className={'mt-4 border-y  border-y-[#D5D5D5] py-5'}>
        <div className="grid grid-cols-12 gap-4">
          {MinterRewardLoading ? (
            <div className=" col-span-12 md:col-span-7 flex justify-center items-center flex-1  h-[124px] w-full">
              <div className="justify-center items-center self-center">
                <div className="flex flex-col gap-5">
                  <Spinner className="animate-spin h-6" />
                </div>
              </div>
            </div>
          ) : (
            <div className=" col-span-12 md:col-span-7 flex flex-col gap-2">
              <span className="text-[#616161] font-medium">
                Rewards Generated
              </span>
              <span className="text-[#1B9FDA] text-xl font-medium">
                {formatNumberWithCommas(
                  Number(
                    toFixedFormat(
                      Number(MinterRewards?.totalAmount || 0),
                      2
                    )
                  )
                )}
                {MinterRewards?.totalTokenName}
              </span>
              <div className=" py-3">
                {/* border-t border-t-[#D5D5D5] */}
                <div className="flex flex-col  md:flex-row md:gap-8 gap-3">
                  {MinterRewards?.rewards?.map((token: any, index: any) => {
                    return (
                      <div
                        className="flex justify-between items-end gap-3"
                        key={token.tokenName}
                      >
                        <div className="flex flex-col ">
                          <span className="text-[#616161] font-medium">
                            {token?.tokenDetails?.tokenName}
                          </span>
                          
                          <span className="text-[#121212] text-lg font-medium">
                            {formatNumberWithCommas(
                              Number(
                                toFixedFormat(
                                  Number(token.tokenAmount || 0),
                                  2
                                )
                              )
                            )}
                          </span>
                        </div>
                        {hardwareClaimLoading && claimIndex === index ? (
                          <div className="flex justify-center items-center flex-1 w-full">
                            <div className="justify-center items-center self-center">
                              <div className="flex flex-col gap-5">
                                <Spinner className="animate-spin h-6" />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <button
                            onClick={() => {
                              const data = {
                                tokenId: token.tokenDetails?._id,
                                userMinter: minter?._id,
                              };
                              setClaimIndex(index);
                              token.tokenAmount &&
                                hardwareGeneratedRewardClaimfunc(data);
                            }}
                            disabled={
                              (hardwareClaimLoading && claimIndex === index) ||
                              !token.tokenAmount
                            }
                            type="button"
                            className={`px-4 h-8 rounded-[16px] flex justify-center items-center text-sm gap-1 border border-[#00A5EF] text-[#00A5EF] bg-white
                             ${(hardwareClaimLoading && claimIndex === index) || !token.tokenAmount ? '!bg-[#C0D5DF] cursor-not-allowed border border-[#D5D5D5] text-white' : 'cursor-pointer'} `}
                          >
                            Claim
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <TotalLinked minter={{ ...minter }} nftStakePercentage={nftStakePercentage} />
        </div>
      </div>

      <div className="col-span-12 md:col-span-12 border border-[#D9E4F1] px-3 py-4 my-4 rounded-lg bg-[#FFFFFF]">
        <div className="flex-col md:flex-row flex gap-2 justify-between items-start  md:items-center">
          <span className="text-[#616161] text-lg font-medium">
            Minter’s Daily Activity
          </span>
          <div className="flex gap-2">
            <div className="flex gap-2">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(Number(e.target.value))}
                className="border border-[#D5DBE0] rounded-[10px] p-2 text-[#616161] "
              >
                {monthsData.map((month, index) => (
                  <option key={index} value={index} className="text-[#616161]">
                    {month}
                  </option>
                ))}
              </select>

              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(Number(e.target.value))}
                className="border border-[#D5DBE0] rounded-[10px] p-2 text-[#616161] "
              >
                {yearsData.map((year) => (
                  <option key={year} value={year} className="text-[#616161]">
                    {year}
                  </option>
                ))}
              </select>
              {/* <CloudKSelect
                options={monthsData.map((month) => ({
                  label: month,
                  value: month.toLowerCase(),
                }))}
                placeholder="Month"
                onChange={handleMonthChange} // Handle the option change
                selectedOption={selectedMonth}
              />
              <CloudKSelect selectedOption={selectedYear}
                options={yearsData?.map((year) => ({
                  label: year,
                  value: year.toString(),
                }))} placeholder="Year" onChange={handleYearChange} /> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 mt-4">
          <div className="flex gap-6">
            <div className="flex gap-2">
              <div className="bg-[#E7E7E7] w-4 h-[14px] rounded-[2px]"></div>
              <span className="text-[#939393] text-xs font-medium">
                Non Eligible
              </span>
            </div>
            <div className="flex gap-2">
              <div className="bg-[#D4FBB9] w-4 h-[14px] rounded-[2px]"></div>
              <span className="text-[#939393] text-xs font-medium">
                Eligible
              </span>
            </div>
          </div>
          {dailyActivityLoading ? (
            <div className="flex justify-center items-center flex-1  h-64 w-full">
              <div className="justify-center items-center self-center">
                <div className="flex flex-col gap-5">
                  <Spinner className="animate-spin h-6" />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap gap-3 w-full ">
              {Array.from({ length: daysInMonth }, (_, i) => i + 1)?.map(
                (day: any) => (
                  <div
                    key={day}
                    className={`border flex justify-center items-center rounded h-7 w-7 ${true ? ' bg-[#D4FBB9]' : ' bg-[#E7E7E7]'}
                                     text-black text-sm font-medium`}
                  >
                    {day}
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>

      <div className=" border-t border-t-[#D5D5D5] py-5 flex flex-col">
        <div className="flex justify-between items-center gap-2">
          <span className="text-[#616161] text-lg font-medium">
            Linked License
          </span>
          <span
            onClick={() => {
              navigate('/defi-360/cloudk/transaction-history');
            }}
            className="text-xs text-[#292929] underline cursor-pointer"
          >
            View Rewards History
          </span>
        </div>

        <div className="flex justify-start gap-4 mt-3 overflow-hidden">
          {minterLinkedLicensesLoading ? (
            <div className="flex justify-center items-center flex-1  h-64 w-full">
              <div className="justify-center items-center self-center">
                <div className="flex flex-col gap-5">
                  <Spinner className="animate-spin h-6" />
                </div>
              </div>
            </div>
          ) : (
            <>
              {minterLinkedLicenses?.list?.length > 0 ? (
                <SimpleSlider
                  onSwiper={(swiper) => {
                    // sliderRef.current = swiper;
                  }}
                  // onSlideChange={(swiper) => {
                  //     setCurrentIndex(swiper.activeIndex);
                  //     setIsAtEnd(swiper.isEnd); // Update end status here
                  // }}
                  className="w-full"
                  spaceBetween={8}
                  slidesPerView={'auto'}
                  modules={[Navigation]}
                  navigation={false} // Disable default Swiper navigation
                  breakpoints={{
                    768: {
                      slidesPerView: 'auto',
                    },
                  }}
                >
                  {minterLinkedLicenses?.list?.map(
                    (license: any, index: number) => {
                      return (
                        <SwiperSlide
                          key={index}
                          className="!w-[220px] h-[271px] cursor-grab bg-[#FFFFFF]"
                        >
                          <div
                            key={index}
                            className="border border-[#D9E4F1] rounded  relative p-3 flex flex-col gap-3 "
                          >
                            <div className="w-full flex justify-center mt-3">
                              <ImageWithFallback
                                src={license.licenseImage?.link}
                                alt={license.identifier}
                                classNames="h-[87px] w-[63px]"
                                fallbackSrc={''}
                              />
                            </div>
                            <div className="w-full flex justify-center items-center flex-col gap-1">
                              <span className="font-medium text-[#121212] text-xs">
                                {license.licenceName}
                              </span>
                              <span className="text-xs text-[#939393]">
                                {license.identifier}
                              </span>
                            </div>
                            <div className="flex justify-between">
                              <div className="flex flex-col gap-1">
                                <span className="font-medium text-[#121212] text-xs">
                                  {formatNumberWithCommas(
                                    Number(
                                      toFixedWithoutRoundingOff(
                                        Number(license?.totalTokenAmount || 0),
                                        2
                                      )
                                    )
                                  )}
                                </span>
                                <span className="text-xs text-[#939393]">
                                  Available {license.TokenName}
                                </span>
                              </div>
                              <div className="flex flex-col gap-1">
                                <span className="font-medium text-[#121212] text-xs">
                                  {license.lockNft}
                                </span>
                                <span className="text-xs text-[#939393]">
                                  # of NFTs
                                </span>
                              </div>
                            </div>
                            {deLinkLoading && deLinkIndex === index ? (
                              <div className="flex justify-center items-center flex-1  w-full">
                                <div className="justify-center items-center self-center">
                                  <div className="flex flex-col gap-5">
                                    <Spinner className="animate-spin h-6" />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <button
                                onClick={() => {
                                  if (license.type === 'LINKED') {
                                    setDeLinkIndex(index);
                                    const data = {
                                      minterId: license?.userMinterId,
                                      licenseId: license?.userlicenseId,
                                    };
                                    deLinkLicenseFunc(data);
                                  }
                                }}
                                disabled={
                                  license.type !== 'LINKED' ||
                                  (deLinkLoading && deLinkIndex === index)
                                }
                                type="button"
                                className={`p-4 h-6 rounded-[16px] flex justify-center items-center text-xs gap-1 text-[#FFFFFF]  
                                        ${license.type === 'LINKED' && !deLinkLoading ? 'bg-[#00A5EF]' : 'bg-[#C0D5DF] cursor-not-allowed'} `}
                              >
                                De-link
                              </button>
                            )}
                          </div>
                        </SwiperSlide>
                      );
                    }
                  )}
                </SimpleSlider>
              ) : (
                <div className="flex-1 flex justify-center  items-center w-full xxs:px-2 sm:px-0  ">
                  <div className="flex flex-col gap-2 items-center">
                    <div className="w-32 h-auto">
                      <img src={EmptyLicenseList} alt="empty transactions" />
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                      <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium">
                        You don’t have any License
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className=" border-t border-t-[#D5D5D5] py-5 flex flex-col mt-2 bg-[#FFFFFF]">
        <CloudKTable
          // @ts-ignore
          rows={rows || []}
          title={'Hardware Transaction History'}
          columns={columns}
          loading={machineRewardHistoryLoading}
          filters={[
            <CloudKSelect
              options={machineRewardsHistoryLicensesDropdown.map((item) => ({
                label: item.identifier,
                value: item.userlicenseId,
              }))}
              placeholder={'License'}
              onChange={handleLicenseChange} // Handle the option change
              selectedOption={filters.selectedLicense}
            />,
            <RangeDatePicker
              handleDateDate={(fromDate, toDate) =>
                handleDateChange(fromDate, toDate)
              }
              resetFilter={resetFilter}
              toDateClassName={`!h-9 text-secondaryBlack text-sm`}
              fromDateClassName={`!h-9 text-secondaryBlack text-sm`}
              // setDateChanged={true}
              // fromDateDisable={resetDisable}
              // fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
              // toDateDisable={resetDisable}
              // toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
            />,
            <button
              className={`${resetDisable ? ' cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
              type="button"
              disabled={resetDisable}
              onClick={() => {
                setResetFilter(true);
                setFilters({
                  limit: 10,
                  page: 1,
                  selectedLicense: null,
                  fromDate: null,
                  toDate: null,
                  minterId: minter?._id,
                });
              }}
            >
              Reset
            </button>,
          ]}
          // paginationContainerStyle={'!justify-end'}
          // navigationText={`View All Rewards History`}
          // navigationStyle={``}
          // navigationShow={true}
          // navigationContainerStyle={``}
          footerNavigation={{
            text: 'View All Rewards History',
            url: '/defi-360/cloudk/reward-history',
          }}
        />
      </div>
      <GlobalModal
        isOpen={open}
        onClose={handleClose}
        size="3xl"
        childrenClass="!px-0 !py-0 small-scroll "
        title={'Connect a License'}
        noHeader={false}
        headerClassName="!p-6 font-medium text-lg text-[#0E173F]"
        modalCenter={'flex sm:items-center justify-center'}
        fullWidthContent={' border border-gray-500 !mx-0'}
      >
        <LinkedLicense minter={minter} handleClose={handleClose} />
      </GlobalModal>
    </div>
  );
};

export default HardwareDetailModal;
