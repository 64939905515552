import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnlockedLicense, UnlockedLicenseTransaction, VestingSchedule } from '../../types/cloudk.types';

interface Transactions {
  totalPages: number;
  list: UnlockedLicenseTransaction[];
}
interface LicenseUnlockSliceState {
  summaryLoading: boolean;
  summaryData: UnlockedLicense | null;

  vestingTimelineLoading: boolean;
  vestingTimelineData: VestingSchedule | null;

  transactionsLoading: boolean;
  transactionsData: Transactions
}

const initialState: LicenseUnlockSliceState = {
  //summary
  summaryLoading: false,
  summaryData: null,

  vestingTimelineLoading: false,
  vestingTimelineData: null,

  transactionsLoading: false,
  transactionsData: {
    totalPages:1,
    list:[]
  },
};

const licenseUnlockSlice = createSlice({
  name: 'cloudk-license-unlock',
  initialState,
  reducers: {
    // summary
    setSummaryLoading: (state, action: PayloadAction<boolean>) => {
      state.summaryLoading = action.payload;
    },
    setSummaryData: (state, action: PayloadAction<any>) => {
      state.summaryData = action.payload;
    },
    setVestingTimelineLoading: (state, action: PayloadAction<boolean>) => {
      state.vestingTimelineLoading = action.payload;
    },
    setVestingTimelineData: (state, action: PayloadAction<any>) => {
      state.vestingTimelineData = action.payload;
    },
    setTransactionsLoading: (state, action: PayloadAction<boolean>) => {
      state.transactionsLoading = action.payload;
    },
    setTransactionsData: (state, action: PayloadAction<Transactions>) => {
      state.transactionsData = action.payload;
    },
  },
});

export const licenseUnlockActions = licenseUnlockSlice.actions;
export const licenseUnlockSelector = (state: any) => state.cloudkLicenseUnlock as LicenseUnlockSliceState;
export default licenseUnlockSlice.reducer;
