import React, { useState } from 'react';
import GlobalTextInput from '../../../../../../components/Global/GlobalTextInput';
import { hardwareImg } from '../../../../../../images/cloudk';
import GlobalButton from '../../../../../../components/Global/GlobalButton';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import { useSelector } from 'react-redux';
import ImageWithFallback from '../../../Common/ImageWithFallback/ImageWithFallback';
const data = {
  name: 'MINT-012314827482481',
  image: hardwareImg,
};
const ConnectNewMinter = ({ minter, handleCloseConnectModal }: any) => {
  const [minterName, setMinterName] = useState(minter.minterData.name);
  const [serialNumber, setSerialNumber] = useState<string | null>(null);
  const { connectNewMinterLoading } = useSelector(selectCloudSlice);
  const { connectNewMinterFunc, getAllHardwares } = useCloudkActions();
  return (
    <div className="flex flex-col gap-2  px-6 pb-6">
      <div className="grid grid-cols-12 gap-4 items-center">
        <div className="col-span-12 sm:col-span-6 order-2 sm:order-1 flex flex-col gap-2 w-full">
          <div className="text-defaultBlack text-sm font-medium">
            Minter Name <span className="text-redDanger">*</span>
          </div>
          <div className="w-full relative">
            <GlobalTextInput
              name="name"
              placeholder="Enter minter name"
              type="text"
              className="!rounded-[50px] bg-gray-50"
              animationClasses="!rounded-[50px]"
              value={minterName || ''}
              style={{ cursor: 'default', appearance: 'none' }}
              errorStyle="!text-left"
              onChange={(e) => !minterName && setMinterName(e.target.value)}
              disabled={minterName ? true : false}
            />
          </div>
        </div>

        <div className="col-span-12 sm:col-span-6 order-1 sm:order-2 flex justify-center sm:justify-end">
          <div className="w-64 h-40">
            <ImageWithFallback
              fallbackSrc={''}
              src={minter?.minterData?.documents[0]?.link}
              alt="hardwareImage"
              classNames="h-full w-full"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-2 w-full">
          <div className="text-defaultBlack text-sm font-medium">
            Minter Serial Number <span className="text-redDanger">*</span>
          </div>
          <div className="w-full relative">
            <GlobalTextInput
              name="serial-number"
              placeholder="Type Code"
              type="number"
              className={'!rounded-[50px] bg-gray-50'}
              animationClasses="!rounded-[50px]"
              value={serialNumber || ''}
              style={{ cursor: 'default', appearance: 'none' }}
              //   errorMessage={
              //       (inputAmount ?? 0) > availableCloudKBalance ?
              //           `You can’t stake more than $ ${Number(availableCloudKBalance)?.toFixed(2)}` : ''
              //   }
              errorStyle={'!text-left'}
              onChange={(e) => {
                const value = e.target.value;
                setSerialNumber(value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-3 w-full">
        <GlobalButton
          text="Confirm Changes"
          type="button"
          loading={connectNewMinterLoading}
          disabled={
            !connectNewMinterLoading && minterName && serialNumber
              ? false
              : true
          }
          containerClassName={'w-full'}
          classNames={`!w-full rounded-[10px] !text-base !py-[10px] px-5 min-w-max place-item-end 
               ${!connectNewMinterLoading && minterName && serialNumber ? '!bg-updateBtnGredient' : 'bg-[#C0D5DF]'} font-medium !text-[#FFFFFF]`}
          onClick={async () => {
            const data = {
              minterId: minter?._id,
              serialNumber: serialNumber,
            };
            const response = await connectNewMinterFunc(data);
            if (response?.status) {
              handleCloseConnectModal();
              // getAllHardwares();
            }
          }}
        />
      </div>
    </div>
  );
};

export default ConnectNewMinter;
