export const tokenIcon1 = '/img/cloudk/tokenIcon1.png';
export const tokenIcon2 = '/img/cloudk/tokenIcon2.png';
export const licenseImg = '/img/cloudk/licenseImg.png';
export const upIcon = '/img/cloudk/chevrons-upIcon.png';
export const hardwareImg = '/img/cloudk/hardwareImg.png';
export const mobileHardwareImg = '/img/cloudk/hardwaremobile.png';
export const mobileHardwareImgStep1 = '/img/cloudk/connectQRStep1.png';
export const mobileHardwareImgStep2 = '/img/cloudk/mobileHardwareImgStep2.png';
export const mobileHardwareImgStep3 = '/img/cloudk/mobileHardwareImgStep3.png';
export const mobileHardwareImgStep4 = '/img/cloudk/barcode.png';
export const airStreamMachine = '/img/cloudk/airStreamMachine.png';
export const totalLicienceWaterMark = '/img/cloudk/totalLicienceWaterMark.png';
export const totalRewardWaterMark = '/img/cloudk/totalRewardWaterMark.png';
export const totalLinkedWaterMark = '/img/cloudk/totalLinkedWaterMark.png';
