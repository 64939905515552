import React, { useEffect, useState } from 'react';
import CloudKTable from '../../../Common/CloudKTable/CloudKTable';
import CloudKSelect from '../../../Common/CloudKSelect/CloudKSelect';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import {
  formatAlphaNumericDate,
  formatNumberWithCommas,
  toFixedFormat,
  toFixedWithoutRoundingOff,
} from '../../../../../../utils/utils';
import RangeDatePicker from '../../../../../Wallet/RangeDatePicker';
import moment from 'moment';
import { EmptyWithdrawHistoryGif } from '../../../../../../images/wallets';

const columns = [
  { label: 'Sr. #', key: 'id', classNames: 'text-center' },
  { label: 'License ID', key: 'licenseId' },
  { label: 'Transaction Type', key: 'transactionType' },
  { label: 'Amount', key: 'amount' },
  // { label: 'Validity', key: 'endDate' },
  { label: 'Token', key: 'token' },
  { label: 'From', key: 'from' },
  { label: 'Date', key: 'date' },
];

const TransactionHistory = () => {
  const {
    transactionHistoryData,
    transactionHistoryLoading,
    allLiciencesIds,
    allLiciencesStatus,
  } = useSelector(selectCloudSlice);
  const { getCloudKTransactionHistory, getAllLicienceIDsFunc } =
    useCloudkActions();
  const [licenseIdsOption, setLicenseIdsOption] = useState([]);
  const [allLicensesStatusArray, setAllLicensesStatusArray] = useState<any>([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [filters, setFilters] = useState<any>({
    limit: 10,
    page: 1,
    licenseId: null,
    licensesStatus: null,
    fromDate: null,
    toDate: null,
  });
  const [resetDisable, setResetDisable] = useState(true);

  useEffect(() => {
    const noData = !transactionHistoryData?.list?.length;
    const hasFilters =
      filters.licenseId ||
      filters.licensesStatus ||
      filters.fromDate ||
      filters.toDate;

    // Disable if (no data and no filters) OR (data and no filters)
    setResetDisable((noData && !hasFilters) || (!noData && !hasFilters));
  }, [transactionHistoryData, filters]);

  useEffect(() => {
    if (allLiciencesIds?.length > 0) {
      setLicenseIdsOption(
        allLiciencesIds.map((item: any) => ({
          value: item.licenseId,
          label: item.identifier,
        }))
      );
    }
  }, [allLiciencesIds]);

  useEffect(() => {
    if (allLiciencesStatus.length > 0) {
      const statusArray: any = allLiciencesStatus.map((license: any) => ({
        label: license,
        value: license,
      }));

      setAllLicensesStatusArray(statusArray);
    }
  }, [allLiciencesStatus]);

  useEffect(() => {
    getAllLicienceIDsFunc();
    // getAllLicensesStatusFunc();
  }, []);
  useEffect(() => {
    setResetFilter(false);
    getCloudKTransactionHistory({
      page: filters.page,
      limit: filters.limit,
      id: filters?.licenseId?.value,
      type: filters?.licensesStatus?.value,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
    });
  }, [filters]);

  const RenderTag = (type: string) => {
    switch (type) {
      case 'purchase':
        return (
          <span className="py-1 px-2 bg-[#CCEFFF] text-[#147AA7] rounded-md text-xs font-medium">
            Purchase
          </span>
        );
      case 'add-stake':
        return (
          <span className="py-1 px-2 bg-[#0B6D5E1A] text-[#0B6D5E] rounded-md text-xs font-medium">
            Add Stake
          </span>
        );
      case 'reward':
        return (
          <span className="py-1 px-2 bg-[#FFA2A245] text-[#A20000] rounded-md text-xs font-medium">
            Rewards
          </span>
        );
      case 'claimed':
        return (
          <span className="py-1 px-2 bg-[#CCEFFF] text-[#147AA7] rounded-md text-xs font-medium">
            Claimed Rewards
          </span>
        );
      default:
        return null; // Or a fallback message/component if needed
    }
  };
  const rows =
    transactionHistoryData?.list?.map((transaction: any, index: number) => ({
      id: filters.limit * filters.page + index + 1 - 10,
      // licenseId: transaction.userLicenseId,
      licenseId: transaction?.userLicenseinfo[0]?.identifier,
      transactionType: RenderTag(transaction.type),
      amount: toFixedFormat(
        transaction?.tokenAmount ?? transaction.totalPrice ?? 0,
        4
      ),
      token: transaction?.token?.tokenName ?? '-',
      // endDate: formatAlphaNumericDate(transaction?.lockedLicense?.endDate),
      from: transaction.from ?? '-',
      date: formatAlphaNumericDate(transaction.createdAt),
    })) || [];
  const handleLicenseChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilters((prev: any) => ({
      ...prev,
      page: 1,
      licenseId: selectedOption,
    }));
  };
  const handleTypeChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilters((prev: any) => ({
      ...prev,
      page: 1,
      licensesStatus: selectedOption,
    }));
  };

  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');

    // setDate({ fromDate: from, toDate: to });
    setFilters((prev: any) => ({
      ...prev,
      page: 1,
      fromDate: from,
      toDate: to,
    }));
  };

  const noDataUI = <div className="flex-1 flex justify-center items-center w-full mt-5">
            <div className="flex flex-col gap-2 items-center ">
              <div className=" w-36 h-auto">
                <img src={EmptyWithdrawHistoryGif} alt="empty transactions" />
              </div>
              <div className="flex flex-col gap-1 items-center">
                <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium">
                  Sorry, No Transactions History Found.
                </span>
                <p className="text-[#7D7D7D] text-sm">
                  Its getting boring in here. Time to treat yourself.
                </p>
              </div>
            </div>
          </div>;

  return (
    <div className="bg-[#FFFFFF]">
      <CloudKTable
        rows={rows}
        columns={columns}
        title="Transaction History"
        loading={transactionHistoryLoading}
        currentPage={filters.page}
        totalPages={transactionHistoryData?.totalPages ?? 1}
        onPageChange={(page) => setFilters((prev: any) => ({ ...prev, page }))}
        renderNoData={noDataUI}
        filters={[
          <CloudKSelect
            options={licenseIdsOption}
            placeholder="License ID"
            onChange={handleLicenseChange} // Handle the option change
            selectedOption={filters.licenseId}
          />,
          <CloudKSelect
            selectedOption={filters.licensesStatus}
            options={allLicensesStatusArray}
            placeholder="Transaction Type"
            onChange={handleTypeChange}
          />,
          <RangeDatePicker
            handleDateDate={handleDateDate}
            resetFilter={resetFilter}
            toDateClassName={`!h-9 text-secondaryBlack text-sm`}
            fromDateClassName={`!h-9 text-secondaryBlack text-sm`}
            // setDateChanged={true}
            // fromDateDisable={resetDisable}
            // fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
            // toDateDisable={resetDisable}
            // toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
          />,
          <button
            className={`${resetDisable ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
            type="button"
            disabled={resetDisable}
            onClick={() => {
              setResetFilter(true);
              setFilters({
                limit: 10,
                page: 1,
                licenseId: null,
                licensesStatus: null,
                fromDate: null,
                toDate: null,
              });
            }}
          >
            Reset
          </button>,
        ]}
        // navigationContainerStyle="!justify-center"
        // totalPagesProp={transactionHistoryData?.totalPages}
        // currentPageProp={transactionHistoryData?.currentPage}
      />
    </div>
  );

  function getTotalPrice(transaction: any): React.ReactNode {
    return formatNumberWithCommas(
      Number(toFixedWithoutRoundingOff(Number(transaction.totalPrice), 2))
    );
  }
};

export default TransactionHistory;
