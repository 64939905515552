import { mobileHardwareImgStep4 } from '../../../../../../images/cloudk';

const Step4 = () => {
  return (
    <div className="">
      <h1 className=" font-medium mb-2">Connect through QR - Step 4</h1>
      <div className="w-full flex flex-col gap-2 items-center">
        <div className="w-full flex justify-center my-3">
          <img src={mobileHardwareImgStep4} alt="" className="h-48" />
        </div>
        <p className=" text-center">
          Open the Homnifi app on the phone with the serial number given below:{' '}
        </p>
        <span className="py-2 px-8 border rounded-md font-medium border-[#1B9FDA] bg-[#E0F7FE]">
          g43h 5j4k j43k k21l
        </span>
        <p className="text-[#F0AD47] text-sm">
          Note : undo the shutter of the camera of the phone
        </p>
      </div>
    </div>
  );
};

export default Step4;
