import { Gauge, gaugeClasses, useGaugeState } from '@mui/x-charts/Gauge';
import '../../styles/index.css';
import { useWindowSize } from '../../utils/helper';
const StartText = ({ startText, showStartText = true }: { startText?: string, showStartText?: boolean }) => {

  const { valueAngle } = useGaugeState();
  const size = useWindowSize();
  const fontSize = size.width <= 480 ? 8 : size.width <= 768 ? 14 : 16;

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  return (
    <text
      style={{ fontSize }}
      className="gauge-text"
      x={size?.width <= 480 ? 2 : 5}
      y={170}
    >
      {showStartText && (startText || '0.00')}
    </text>
  );
};

const EndText = ({ limit, endText, showEndText = true }: { limit?: number; endText?: string, showEndText?: boolean }) => {
  const { valueAngle } = useGaugeState();
  const size = useWindowSize();

  if (valueAngle === null) {
    // No value to display
    return null;
  }
  const fontSize = size.width <= 480 ? 8 : size.width <= 768 ? 14 : 16;

  return (
    <text style={{ fontSize }} x={size?.width <= 480 ? 160 : 170} y={170}>
      {showEndText &&  (endText || limit)}
    </text>
  );
};

const DotElement = ({ dotRadius }: any) => {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();
  if (valueAngle === null) {
    // No value to display
    return null;
  }

  return (
    <g>
      <circle
        cx={cx + (outerRadius - dotRadius) * Math.sin(valueAngle)}
        cy={cy - (outerRadius - (dotRadius + 2)) * Math.cos(valueAngle)}
        r={dotRadius}
        fill="white"
        strokeWidth={2}
        stroke="gray"
      />
    </g>
  );
};

type TGaugeChart = {
  containerHeight?: number;
  containerWidth?: number;
  strokeWidth?: number;
  value?: number;
  limit?: number;
  startText?: string;
  endText?: string;
  showStartText?: boolean;
  showEndText?: boolean;
  dotRadius?: number;
};

export default function GaugeChart({
  containerHeight = 200,
  containerWidth = 200,
  strokeWidth,
  value,
  limit,
  startText,
  endText,
  showEndText = true,
  showStartText = true,
  dotRadius = 8,
}: TGaugeChart) {
  const normalizedValue = limit
    ? Math.min(((value || 0) / limit) * 100, 100)
    : 0;

  const settings = {
    width: containerWidth,
    height: containerHeight,
    value: normalizedValue,
    startAngle: -90,
    endAngle: 90,
  };

  return (
    <>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="gradientColor" x1="0" y1="0" x2="1" y2="1">
            <stop stopColor="#8AD5EC" />
            <stop offset="0%" stopColor="#8AD5EC" />
            <stop offset="50%" stopColor="#1D9FDA" />
            <stop offset="100%" stopColor="#F16364" />
          </linearGradient>
        </defs>
      </svg>

      <Gauge
        {...settings}
        cornerRadius="50%"
        sx={(theme) => ({
          [`& .${gaugeClasses.valueArc}`]: {
            fill: 'url(#gradientColor)',
            stroke: '#EEEEEE',
            strokeWidth: 6,
          },
          [`& .${gaugeClasses.referenceArc}`]: {
            fill: '#EEEEEE',
          },
        })}
        text={''}
        disableAxisListener={false}
      >
        <DotElement dotRadius={dotRadius} />
        <StartText startText={startText} showStartText={showStartText} />
        <EndText limit={limit || 10000} endText={endText} showEndText={showEndText} />
      </Gauge>
    </>
  );
}
