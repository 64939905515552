import { mobileHardwareImgStep1 } from '../../../../../../images/cloudk';

const Step1 = () => {
  return (
    <div className="">
      <h1 className=" font-medium mb-2">Connect through QR - Step 1</h1>
      <div className="w-full flex flex-col gap-2  items-center">
        <div className="w-full flex justify-center my-3">
          <img src={mobileHardwareImgStep1} alt="" className="h-48" />
        </div>
        <p className=" text-center">
          Open the Homnifi app on the phone with the serial number given below:{' '}
        </p>
        <span className="py-1 px-4 sm:py-1 sm:px-8  border rounded-md font-medium border-[#1B9FDA] bg-[#E0F7FE]">
          g43h 5j4k j43k k21l
        </span>
      </div>
    </div>
  );
};

export default Step1;
