import React from 'react'
import CardLayout from '../../../../../components/Cards/CardLayout'
import { useSelector } from 'react-redux'
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice'
import { totalLicienceWaterMark } from '../../../../../images/cloudk'
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';

const TotalLicenses = () => {
  const { totalLicenses, activeLicenses, inActiveLicenses, totalCountLoading } = useSelector(selectCloudSlice)

  return (
    <CardLayout className="md:col-span-6 xl:col-span-3 col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow relative bg-[#FFFFFF]">
      {totalCountLoading ? <div className="flex justify-center items-center flex-1  h-full w-full">
        <div className="justify-center items-center self-center">
          <div className="flex flex-col gap-5">
            <Spinner className="animate-spin h-6" />
          </div>
        </div>
      </div> : <section className="flex flex-col gap-9">
        <div className='flex flex-col gap-2'>
          <span className='font-medium text-[#616161]'>Total number of Licenses</span>
          <span className='text-4xl font-medium text-[#001540]'>{totalLicenses}</span>
        </div>

        <div className='flex gap-2'>
          <div className='h-9 w-28 rounded-[100px] bg-[#E7F8F0] border border-[#0E9255] flex justify-center items-center text-[#0E9255]'>{activeLicenses} Active</div>
          <div className='h-9 w-28 rounded-[100px] bg-[#FFF4F3] border border-[#F04438] flex justify-center items-center text-[#F04438]'>{inActiveLicenses} Inactive</div>
        </div>
      </section>} 
      
      
      <img src={totalLicienceWaterMark} alt={'water-mark'} className='absolute bottom-0 right-2 h-full' />
      
    </CardLayout>
  )
}

export default TotalLicenses
