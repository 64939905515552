//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  allLicensesList,
  UserlinkLicenseData,
  UserunlockLicenseData,
} from './cloudkTypes';

export interface CloudkState {
  activeRewardTab: any;
  activeRewardTime: any;
  isGenerationSwitch: boolean;
  totalLicenses: number;
  activeLicenses: number;
  inActiveLicenses: number;
  totalLinked: number;
  activeLinked: number;
  availableLinkedCapacity: number;
  totalStacked: any;
  availableCloudKBalance: number;
  claimableRewardLoading: boolean;
  claimRewardLoading: boolean;
  specificLicenseLoading:boolean;
  rewardAnalyticsGraphLoading: boolean;
  allLicienceLoading: boolean;
  allHardwareLoading: boolean;
  transactionHistoryLoading: boolean;
  machineRewardHistoryLoading: boolean;
  detailDataLoading: boolean;
  upgradeAbleMachinesLoading: boolean;
  createMintingTokenLoading: boolean;
  connectNewMinterLoading: boolean;
  dailyActivityLoading: boolean;
  deLinkLoading: boolean;
  hardwareClaimLoading: boolean;
  claimableRewardAutoLinkLoading: boolean;
  MinterRewardLoading: boolean;
  allLicencesData: {
    currentPage: number;
    list: allLicensesList[];
    totalCount: number;
    totalPages: number;
  };
  totalCountLoading: boolean;
  linkLicenseWithMinterLoading: boolean;
  minterLinkedLicensesLoading: boolean;
  allLiciencesData: any[];
  allHArdwaresData: any;
  rewardAnalyticsGraphData: any[];
  transactionHistoryData: any;
  machineRewardHistoryData: any[];
  upgradeAblesMachinesData: any[];
  detailedData: any[];
  dailyActivityData: any[];
  claimableRewardData: any;
  allUserLinkLicencesData: {
    totalCount: number;
    list: UserlinkLicenseData[];
    totalPages: number;
    currentPage: number;
  };
  allUserUnLockLicencesData: {
    totalCount: number;
    list: UserunlockLicenseData[];
    totalPages: number;
    currentPage: number;
  };
  allUserUnLockLicienceLoading: boolean;
  allUserLinkLicienceLoading: boolean;
  minterRewardGeneratedData: any;
  rewardGeneratedTokens: any;
  minterLinkedLicenses: any;
  allLiciencesIds: any;
  allLiciencesStatus: any;
  MinterRewards: any;
  machineRewardsHistoryLicensesDropdown: any[];
  allMachinesRewardHistoryLoading: boolean;
  allMachinesRewardHistoryData: any[];
}

export const CLOUDK_SLICE_NAME = 'cloudk';

const initialState: CloudkState = {
  activeRewardTab: { key: 'license', label: 'Licenses' },
  activeRewardTime: { key: 'quarterly', label: '3M' },
  claimableRewardData: [],
  isGenerationSwitch: false,
  totalLicenses: 0,
  activeLicenses: 0,
  inActiveLicenses: 0,
  totalLinked: 0,
  activeLinked: 0,
  availableLinkedCapacity: 50,
  totalStacked: {},
  availableCloudKBalance: 20,
  claimableRewardLoading: false,
  claimRewardLoading: false,
  specificLicenseLoading:false,
  rewardAnalyticsGraphLoading: false,
  allLicienceLoading: false,
  allHardwareLoading: false,
  transactionHistoryLoading: false,
  machineRewardHistoryLoading: false,
  allMachinesRewardHistoryLoading: false,
  detailDataLoading: false,
  upgradeAbleMachinesLoading: false,
  createMintingTokenLoading: false,
  connectNewMinterLoading: false,
  dailyActivityLoading: false,
  deLinkLoading: false,
  hardwareClaimLoading: false,
  claimableRewardAutoLinkLoading: false,
  allUserUnLockLicienceLoading: false,
  allUserLinkLicienceLoading: false,
  MinterRewardLoading: false,
  MinterRewards: {},
  allLicencesData: {
    currentPage: 0,
    list: [
      {
        _id: '',
        blv: 0,
        glp: 0,
        name: '',
        status: '',
        image: '',
        rewards: {
          lyk_r: 0,
          lyk_w: 0,
        },
        nft: '',
        licenseExpiry: '',
        isUpgradable: false,
        hardwareConnection: '',
        lockedPeriod: '',
        stakable: 0,
        staked: 0,
        capacity: 0,
        linkStatus: '',
        isExpired: false,
        totalLockedNft: 0,
      },
    ],
    totalCount: 0,
    totalPages: 0,
  },
  allUserLinkLicencesData: {
    currentPage: 0,
    list: [
      {
        _id: '',
        blv: 0,
        glp: 0,
        image: '',
        name: '',
        status: '',
        rewards: {
          lyk_r: {
            total:0,
            _id:''
          },
          lyk_w:  {
            total:0,
            _id:''
          },
        },
        nft: 0,
        licenseExpiry: '',
        hardwareConnection: null,
        isUpgradable: false,
        lockedPeriod: '',
        staked: 0,
        stakable: 0,
      },
    ],
    totalCount: 0,
    totalPages: 0,
  },
  allUserUnLockLicencesData: {
    currentPage: 0,
    list: [
      {
        _id: '',
        vestingDays: 0,
        ptm: 0,
        blv: 0,
        priceMultiplier: 0,
        status: '',
        dailyClaimableAmount: 0,
        isClaimableToday: false,
        todaysClaim: 0,
        name: '',
        licenseId: '',
        image: '',
        totalUnlockableAmount: 0,
        availableClaim: 0,
        futureClaim: 0,
        totalPrice: 0,
      },
    ],
    totalCount: 0,
    totalPages: 0,
  },

  totalCountLoading: false,
  linkLicenseWithMinterLoading: false,
  allLiciencesData: [],
  allHArdwaresData: [],
  rewardAnalyticsGraphData: [],
  transactionHistoryData: [],
  machineRewardHistoryData: [],
  allMachinesRewardHistoryData: [],
  upgradeAblesMachinesData: [],
  detailedData: [],
  dailyActivityData: [],
  minterLinkedLicenses: [],
  minterLinkedLicensesLoading: false,
  minterRewardGeneratedData: [],
  rewardGeneratedTokens: [],
  allLiciencesIds: [],
  allLiciencesStatus: [],
  machineRewardsHistoryLicensesDropdown: [],
};

const cloudkSlice = createSlice({
  name: CLOUDK_SLICE_NAME,
  initialState,
  reducers: {
    setCloudkSlice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const cloudkSliceAction = cloudkSlice.actions;
export const { setCloudkSlice } = cloudkSlice.actions;
export const selectCloudSlice = (state: { [CLOUDK_SLICE_NAME]: CloudkState }) =>
  state[CLOUDK_SLICE_NAME];

export default cloudkSlice.reducer;
