import { useState } from 'react';

const ImageWithFallback = ({ src, fallbackSrc, alt, classNames }:any) => {
    const [imgSrc, setImgSrc] = useState(src);

    return (
        <img
            className={classNames}
            src={imgSrc}
            alt={alt}
            onError={() => setImgSrc(fallbackSrc)}
        />
    );
};

export default ImageWithFallback;