import React, { ChangeEvent } from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'; // for ES6 modules

import '../../styles/index.css';

interface CustomSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  labelPasitionLeft?: boolean;
  className?: string;
  disabled?: boolean;
  parentClassName?: string;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  checked,
  onChange,
  label,
  labelPasitionLeft = true,
  className = '',
  disabled = false,
  parentClassName = '',
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const handleLabelClick = () => {
    onChange(!checked);
  };
  return (
    <div className={`flex items-center gap-8 ${parentClassName}`}>
      {labelPasitionLeft && label && (
        <span
          className="text-sm text-gray-700 cursor-pointer"
          onClick={handleLabelClick}
        >
          {label}
        </span>
      )}

      <Toggle
        disabled={disabled}
        checked={checked}
        icons={false}
        size={20}
        onChange={handleChange}
        className={className}
      />

      {!labelPasitionLeft && label && (
        <span
          className="text-sm text-gray-700 cursor-pointer"
          onClick={handleLabelClick}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default CustomSwitch;
